import React from 'react';
import norrioIconImage from './norrio-icon-256.png';

export interface NorrioIconProps extends Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'alt' | 'src'> {}

const NorrioIcon = (props: NorrioIconProps) => (
  <img {...props} alt={`${norrioCompanyName} Logo`} src={norrioIconImage} />
);
export default NorrioIcon;

export const norrioCompanyName = 'Norrio Capital Partners';
export const norrioCorporateName = `${norrioCompanyName}, LLC`;
